export default [
  {
    path: '/finance',
    name: 'finance',
    component: () => import('@/views/pages/kompack/keuangan/saldo.vue'),
    meta: {
      resource: 'Keuangan',
      toDetail: 'finance',
      action: 'manage',
    },
  },
  {
    path: '/saldo/detail-penarikan/:id',
    name: 'detail-penarikan',
    component: () => import(
      '@/views/pages/kompack/keuangan/detailPenarikan/detailPenarikan.vue'
    ),
    meta: {
      resource: 'Keuangan',
      toDetail: 'saldo',
      action: 'manage',
    },
  },
  {
    path: '/saldo/detail-saldo',
    name: 'detail-saldo',
    component: () => import('@/views/pages/kompack/keuangan/detailSaldo/detailSaldo.vue'),
    meta: {
      resource: 'Keuangan',
      toDetail: 'saldo',
      action: 'manage',
    },
  },
  {
    path: '/saldo/detail-biaya-outbound/:id',
    name: 'detail-biaya-outbound',
    component: () => import(
      '@/views/pages/kompack/keuangan/detailBiayaOutbound/detailBiayaOutbound.vue'
    ),
    meta: {
      resource: 'Keuangan',
      toDetail: 'saldo',
      action: 'manage',
    },
  },
]
