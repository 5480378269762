import axiosIns from '@/libs/axios'
import moment from 'moment'
import { getField, updateField } from 'vuex-map-fields'
import {
  axiosKompack, hideAccount, today, formatYmd, last30, axiosMockapi,
} from '../helpers'

export default {
  namespaced: true,
  state: {
    withdrawDetail: {},
    table: {
      perPage: 20,
      currentPage: 1,
      total: 0,
    },
    userId: '',
    page: 1,
    id: '',
    withdrawTransactions: [],
  },
  getters: {
    getField,
    detail(state) {
      return {
        ...state.withdrawDetail,
        name: state.withdrawDetail.bank_account_name,
        noRek: state.withdrawDetail.bank_account_no,
        bankName: state.withdrawDetail.bank_name,
        lastDate: state.withdrawDetail.last_withdrawal_date,
        withdrawalDate: state.withdrawDetail.withdrawal_date,
      }
    },
    transactions(state) {
      return state.withdrawTransactions.map(transaction => ({
        ...transaction,
        tanggal: moment(new Date(transaction.created_at)).format('DD-MM-YYYY'),
        jam: moment(new Date(transaction.created_at)).format('HH:mm'),
      }))
    },
  },
  mutations: {
    UPDATE_ID(state, id) {
      state.id = id
    },
    UPDATE_USER_ID(state, userId) {
      state.userId = userId
    },
    UPDATE_PER_PAGE(state, perPage) {
      state.table.perPage = perPage
    },
    UPDATE_TOTAL_ROWS(state, total) {
      state.table.total = total
    },
    UPDATE_WITHDRAW_DETAIL(state, data) {
      state.withdrawDetail = data
    },
    UPDATE_WITHDRAW_TRANSACTIONS(state, data) {
      state.withdrawTransactions = data
    },
    RESET_STORE(state) {
      state.withdrawDetail = {}
      state.table.perPage = 20
      state.table.currentPage = 1
      state.table.total = 0
      state.page = 1
      state.withdrawTransactions = []
    },
  },
  actions: {
    async init({ dispatch }) {
      dispatch('getWithdrawDetail')
      dispatch('getWithdrawDetailTransaction')
    },
    async getWithdrawDetail({ commit, state }) {
      return axiosKompack()
        .get(`v1/kompack/finance/withdrawal/detail/${state.id}`, {
          params: {},
        })
        .then(response => {
          commit('UPDATE_WITHDRAW_DETAIL', response.data.data)
        })
    },
    async getWithdrawDetailTransaction({ commit, state }) {
      return axiosKompack()
        .get(`v1/kompack/finance/withdrawal/detail/list/${state.userId}`, {
          params: {
            withdrawal_id: state.id,
            page: state.table.currentPage,
            total_per_page: state.table.perPage,
          },
        })
        .then(response => {
          if (response.data.data) {
            commit('UPDATE_WITHDRAW_TRANSACTIONS', response.data.data.data)
            commit('UPDATE_TOTAL_ROWS', response.data.data.total)
          }
        })
    },
  },
}
