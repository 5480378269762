export default [
  {
    path: '/outbound/pengeluaran-barang',
    name: 'pengeluaran-barang',
    component: () => import(
      '@/views/pages/kompack/outbound/pengeluaranBarang/pengeluaranBarang.vue'
    ),
    meta: {
      resource: 'Outbound',
      action: 'manage',
    },
  },
  {
    path: '/packing',
    name: 'packing',
    component: () => import('@/views/pages/kompack/packing/PackingOption.vue'),
    meta: {
      resource: 'Outbound',
      action: 'manage',
    },
  },
  {
    path: '/packing/detail/:id',
    name: 'detail-packing',
    component: () => import('@/views/pages/kompack/packing/DetailPacking.vue'),
    meta: {
      resource: 'Outbound',
      action: 'manage',
      navActiveLink: 'packing',
    },
  },
  {
    path: '/packing-non-komship/detail/:id',
    name: 'detail-packing-non-komship',
    component: () => import('@/views/pages/kompack/packing/DetailNonKomship.vue'),
    meta: {
      resource: 'Outbound',
      action: 'manage',
      navActiveLink: 'packing',
    },
  },
  {
    path: '/packing-non-komship/order/detail/:id',
    name: 'detail-order-packing-non-komship',
    component: () => import('@/views/pages/kompack/packing/DetailOrderNonKomship.vue'),
    meta: {
      resource: 'Outbound',
      action: 'manage',
      navActiveLink: 'packing',
    },
  },
  {
    path: '/packing/order/detail/:id',
    name: 'detail-order-packing',
    component: () => import('@/views/pages/kompack/packing/DetailOrderPacking.vue'),
    meta: {
      resource: 'Outbound',
      action: 'manage',
      navActiveLink: 'packing',
    },
  },
  {
    path: '/outbound/pengeluaran-barang/detail/:id',
    name: 'detail-penarikan-barang',
    component: () => import(
      '@/views/pages/kompack/outbound/detailPenarikanBarang/detailPenarikanBarang.vue'
    ),
    meta: {
      resource: 'Outbound',
      action: 'manage',
    },
  },
  {
    path: '/outbound/pengajuan-kerusakan-barang',
    name: 'pengajuan-kerusakan-barang',
    component: () => import(
      '@/views/pages/kompack/outbound/pengajuanBarangRusak/pengajuanBarangRusak.vue'
    ),
    meta: {
      resource: 'Outbound',
      action: 'manage',
    },
  },
]
