export default [
  {
    path: '/info-gudangku',
    name: 'info-gudang',
    component: () => import('@/views/pages/kompack/gudang/InfoGudang/index'),
    meta: {
      resource: 'DataInbound',
      toDetail: 'infogudang',
      action: 'manage',
    },
  },
  {
    path: '/stok-gudang',
    name: 'stok-gudang',
    component: () => import('@/views/pages/kompack/gudang/StokGudang/StokGudang.vue'),
    meta: {
      resource: 'DataInbound',
      toDetail: 'stokgudang',
      action: 'manage',
    },
  },
  {
    path: '/detail-stock',
    name: 'detail-stock',
    params: 'id, adatapartner',
    component: () => import('@/views/pages/kompack/gudang/StokGudang/DetailStock.vue'),
    meta: {
      resource: 'DataInbound',
      toDetail: 'stokgudang',
      navActiveLink: 'stok-gudang',
      action: 'manage',
    },
  },
  {
    path: '/data-inbound',
    name: 'data-inbound',
    component: () => import('@/views/pages/kompack/gudang/DataInbound/List.vue'),
    meta: {
      resource: 'DataInbound',
      toDetail: 'datainbound',
      action: 'manage',
    },
  },
  {
    path: '/data-inbound/detail/:id',
    name: 'detail-inbound',
    component: () => import('@/views/pages/kompack/gudang/DataInbound/Detail.vue'),
    meta: {
      resource: 'DataInbound',
      toDetail: 'datainbound',
      action: 'manage',
    },
  },
]
