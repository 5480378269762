import secureLocalStorage from '@/libs/secureLocalstorage'

export default {
  namespaced: true,
  state: {
    userData: secureLocalStorage.get('userData')
      ? secureLocalStorage.get('userData')
      : {},
  },
  mutations: {
    UPDATE_USER_DATA(state, val) {
      state.userData = val
    },
  },
  actions: {},
}
