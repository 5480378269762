import { getField, updateField } from 'vuex-map-fields'
import { axiosKomship } from '../helpers'

export default {
  namespaced: true,
  state: {
    ticket_type_list: [],
  },
  getters: {
    getField,
  },
  mutations: {
    UPDATE_TICKET_TYPE_LIST(state, data) {
      state.ticket_type_list = data
    },
  },
  actions: {
    async init({ dispatch }) {
      dispatch('getTicketTypeList')
    },
    async getTicketTypeList({ commit, state }) {
      return axiosKomship()
        .get('/v1/ticket-mitra/ticket-type/list')
        .then(response => {
          commit('UPDATE_TICKET_TYPE_LIST', response.data.data)
        })
    },
  },
}
