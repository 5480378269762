import axiosIns from '@/libs/axios'
import moment from 'moment'
import { getField, updateField } from 'vuex-map-fields'
import {
  axiosKompack, hideAccount, today, formatYmd, last30, axiosMockapi, kompackDate,
} from '../helpers'

export default {
  namespaced: true,
  state: {
    withdrawDetail: {},
    table: {
      perPage: 20,
      currentPage: 1,
      total: 0,
    },
    page: 1,
    withdrawTransactions: [],
    dateRange: {
      startDate: last30,
      endDate: today,
    },
    partnerSelected: '',
    listPartnersState: [],
    saldoDetail: {},
  },
  getters: {
    getField,
    detail(state) {
      return {
        ...state.withdrawDetail,
        name: state.withdrawDetail.bank_account_name,
        noRek: state.withdrawDetail.bank_account_no,
        bankName: state.withdrawDetail.bank_name,
        lastDate: state.withdrawDetail.last_withdrawal_date,
        withdrawalDate: state.withdrawDetail.withdrawal_date,
      }
    },
    transactions(state) {
      return state.withdrawTransactions.map(transaction => ({
        ...transaction,
        tanggal: moment(new Date(transaction.created_at)).format('DD-MM-YYYY'),
        jam: moment(new Date(transaction.created_at)).format('HH:mm'),
      }))
    },
    saldoDetail(state) {
      return {
        user_id: state.saldoDetail.user_id,
        balance: state.saldoDetail.balance,
        history: state.saldoDetail.history?.data.map(data => ({
          ...data,
          tanggal: moment(new Date(data.date)).format('DD-MM-YYYY'),
          jam: moment(new Date(data.date)).format('HH:mm'),
        })),
      }
    },
    listPartners(state) {
      return state.listPartnersState.map(item => ({
        text: item.partner_name,
        value: item.partner_name,
      }))
    },
  },
  mutations: {
    updateField,
    UPDATE_PER_PAGE(state, perPage) {
      state.table.perPage = perPage
    },
    UPDATE_TOTAL_ROWS(state, total) {
      state.table.total = total
    },
    UPDATE_LIST_PARTNERS(state, listPartners) {
      state.listPartnersState = listPartners
    },
    UPDATE_SALDO_DETAIL(state, detail) {
      state.saldoDetail = detail
    },
    RESET_STORE(state) {
      state.withdrawDetail = {}
      state.table.perPage = 20
      state.table.currentPage = 1
      state.table.total = 0
      state.page = 1
      state.withdrawTransactions = []
      state.dateRange.startDate = kompackDate
      state.dateRange.endDate = today
      state.partnerSelected = ''
      state.listPartnersState = []
      state.saldoDetail = {}
    },
  },
  actions: {
    init({ dispatch }) {
      return new Promise((resolve, reject) => {
        dispatch('getBalanceHistory')
          .then(res => resolve(res))
          .catch(err => reject(err))
        dispatch('getListPartners')
          .then(res => resolve(res))
          .catch(err => reject(err))
      })
    },

    // LIST PARTNER
    getListPartners({ commit, state }) {
      return axiosKompack()
        .get('v1/kompack/list-partner-by-mitra')
        .then(response => {
          commit('UPDATE_LIST_PARTNERS', response.data.data)
        })
    },

    // GET BALANCE HISTORY
    getBalanceHistory({ commit, state }) {
      return axiosKompack()
        .get('v1/kompack/finance/balance-history', {
          params: {
            start_date: formatYmd(state.dateRange.startDate),
            end_date: formatYmd(state.dateRange.endDate),
            name: state.partnerSelected,
            total_per_page: state.table.perPage,
            page: state.table.currentPage,
          },
        })
        .then(response => {
          commit('UPDATE_SALDO_DETAIL', response.data.data)
          commit('UPDATE_TOTAL_ROWS', response.data.data.history.total)
        })
    },
  },
}
