export default [
  {
    path: '/pin',
    name: 'pin',
    component: () => import('@/views/pages/kompack/setting/pin/Pin.vue'),
    meta: {
      resource: 'Pin',
      toDetail: 'pin',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Dashboard',
        },
        {
          text: 'PIN',
          active: true,
        },
      ],
    },
  },
  {
    path: '/rekening-bank',
    name: 'rekening-bank',
    component: () => import('@/views/pages/kompack/setting/rekeningBank/Index.vue'),
    meta: {
      resource: 'RekeningBank',
      toDetail: 'rekeningbank',
      action: 'manage',
    },
  },
  {
    path: '/add-bank',
    name: 'add-bank',
    component: () => import('@/views/pages/kompack/setting/rekeningBank/Add.vue'),
    meta: {
      navActiveLink: 'rekening-bank',
      resource: 'RekeningBank',
      toDetail: 'rekeningbank',
      action: 'manage',
    },
  },
  {
    path: '/edit-bank/:id/:id',
    name: 'edit-bank',
    component: () => import('@/views/pages/kompack/setting/rekeningBank/Edit.vue'),
    meta: {
      navActiveLink: 'rekening-bank',
      resource: 'RekeningBank',
      toDetail: 'rekeningbank',
      action: 'manage',
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/pages/kompack/setting/profile'),
    meta: {
      resource: 'Profile',
      toDetail: 'profile',
      action: 'manage',
    },
  },
]
