export default [
  {
    path: '/return-monitor',
    name: 'package-return',
    component: () => import('@/views/pages/komship/monitoring-retur/List.vue'),
    meta: {
      resource: 'Mitra Ticketing',
      action: 'manage',
    },
  },
  {
    path: '/data-order/detail-order/:order_id',
    name: 'detail-order',
    component: () => import('@/views/pages/DetailOrder/DetailOrder.vue'),
    meta: {
      resource: 'Mitra Ticketing',
      action: 'manage',
      navActiveLink: 'package-return',
    },
  },
]
