import { axiosKomship } from '../helpers'

export default {
  namespaced: true,
  state: () => ({
    deliveryAttempt: 0,
    deliveryFirstTimes: 0,
    lessHoldDay: 0,
    holdDuration: 0,
    totalDeliveryAttempt: 0,
    totalHoldDuration: 0,
    less4holdDay: 0,
  }),
  mutations: {
    UpdateSlaMonitoring(state, response) {
      state.deliveryAttempt = response.data.data?.avg_delivery_attempt ? response.data.data.avg_delivery_attempt : 0
      state.holdDuration = response.data.data?.avg_hold_duration ? response.data.data.avg_hold_duration : 0
      state.deliveryFirstTimes = response.data?.data.delivery_1_times ? response.data.data.delivery_1_times : 0
      state.totalDeliveryAttempt = response.data.data?.total_delivery_attempt ? response.data.data.total_delivery_attempt : 0
      state.totalHoldDuration = response.data.data?.total_hold_duration ? response.data.data.total_hold_duration : 0
      state.less4holdDay = response.data.data?.less_4_hold_day ? response.data.data.less_4_hold_day : 0
    },
  },
  actions: {
    FetchSlaMonitoring({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axiosKomship().get(`/v1/ticket-mitra/sla/monitoring?user_id=${payload.id}&start_date=${payload.startDate}&end_date=${payload.endDate}`).then(response => {
          commit('UpdateSlaMonitoring', response)
          resolve(response)
        }).catch(err => reject(err.response))
      })
    },
    ExportData({ commit }, payload) {
      return new Promise((resolve, reject) => {
        // api/v1/sla/export?start_date=2022-01-01&end_date=2023-04-18&shipping=SAP&type=delivery_attempt_1
        axiosKomship().get(`/v1/sla/export?start_date=${payload.startDate}&end_date=${payload.endDate}&shipping=${payload.shipping}&type=${payload.withHoldDuration}`).then(response => {
          resolve(response)
        }).catch(err => {
          reject(err)
        })
      })
    },
  },
}
