export default [
  {
    path: '/kendala-pengiriman',
    name: 'issue-ticketing',
    component: () => import('@/views/pages/komship/deliver-constraints/Ticketing.vue'),
    meta: {
      resource: 'Mitra Ticketing',
      action: 'manage',
    },
  },
  {
    path: '/kendala-pengiriman/detail/:ticket_id',
    name: 'detail-ticketing-user',
    component: () => import('@/views/pages/komship/deliver-constraints/detail/DetailTicketing.vue'),
    meta: {
      name: 'detail-ticketing-user',
      routeTicket: 'ticketing-user',
      navActiveLink: 'issue-ticketing',
      resource: 'Mitra Ticketing',
      action: 'manage',
    },
  },
  {
    path: '/kendala-pengiriman/detail-kolaborator/:ticket_id',
    name: 'detail-ticketing-user',
    component: () => import('@/views/pages/komship/deliver-constraints/detail-collaborator/DetailTicketingCollaborator.vue'),
    meta: {
      name: 'detail-ticketing-collaborator',
      routeTicket: 'ticketing-collaborator',
      navActiveLink: 'issue-ticketing',
      resource: 'Mitra Ticketing',
      action: 'manage',
    },
  },
  {
    path: '/kendala-pengiriman/respon-massal',
    name: 'bulk-response',
    component: () => import('@/views/pages/komship/deliver-constraints/response-massal/ResponseMassal.vue'),
    meta: {
      name: 'bulk-response',
      routeTicket: 'ticketing-user',
      navActiveLink: 'issue-ticketing',
      resource: 'Mitra Ticketing',
      action: 'manage',
    },
  },
]
