export default [
  {
    path: '/dashboard-warehouse',
    name: 'dashboard-warehouse',
    component: () => import('@/views/pages/kompack/dashboard/saldo.vue'),
    meta: {
      resource: 'DashboardWarehouse',
      action: 'manage',
    },
  },
]
