import axiosIns from '@/libs/axios'
import moment from 'moment'
import { getField, updateField } from 'vuex-map-fields'
import {
  axiosKompack, hideAccount, today, formatYmd, last30, axiosMockapi, formatMh, formatMhNum,
} from '../helpers'

export default {
  namespaced: true,
  state: {
    withdrawDetail: {},
    table: {
      perPage: 20,
      currentPage: 1,
      totalRows: 0,
      offset: 0,
    },
    userId: '',
    page: 1,
    balanceHistoryId: '',
    withdrawTransactions: [],
    dateSelected: '',
    detailOrderBalanceHistory: [],
    detailBalanceHistory: {},
  },
  getters: {
    getField,
    detail(state) {
      return {
        ...state.withdrawDetail,
        name: state.withdrawDetail.bank_account_name,
        noRek: state.withdrawDetail.bank_account_no,
        bankName: state.withdrawDetail.bank_name,
        lastDate: state.withdrawDetail.last_withdrawal_date,
        withdrawalDate: state.withdrawDetail.withdrawal_date,
      }
    },
    transactions(state) {
      return state.withdrawTransactions.map(transaction => ({
        ...transaction,
        tanggal: moment(new Date(transaction.created_at)).format('DD-MM-YYYY'),
        jam: moment(new Date(transaction.created_at)).format('HH:mm'),
      }))
    },
  },
  mutations: {
    updateField,
    UPDATE_ID(state, id) {
      state.id = id
    },
    UPDATE_BALANCE_HISTORY_ID(state, balanceHistoryId) {
      state.balanceHistoryId = balanceHistoryId
    },
    UPDATE_BALANCE_HISTORY(state, detailBalanceHistory) {
      state.detailBalanceHistory = detailBalanceHistory
    },
    UPDATE_ORDER_BALANCE_HISTORY(state, detailOrderBalanceHistory) {
      state.detailOrderBalanceHistory = detailOrderBalanceHistory
    },
    UPDATE_PER_PAGE(state, perPage) {
      state.table.perPage = perPage
    },
    RESET_STORE(state) {
      state.withdrawDetail = {}
      state.userId = ''
      state.page = 1
      state.balanceHistoryId = ''
      state.withdrawTransactions = []
      state.dateSelected = ''
      state.detailOrderBalanceHistory = []
      state.detailBalanceHistory = {}
    },
  },
  actions: {
    async init({ dispatch }) {
      dispatch('getDetailBalanceHistory')
      dispatch('getDetailOrderBalanceHistory')
    },
    getDetailBalanceHistory({ commit, state }) {
      return axiosKompack().get('v1/kompack/finance/detail-balance-history',
        {
          params: {
            balance_history_id: state.balanceHistoryId,
          },
        })
        .then(res => {
          commit('UPDATE_BALANCE_HISTORY', res.data.data)
        })
    },
    async getDetailOrderBalanceHistory({ commit, state }) {
      return axiosKompack().get(
        'v1/kompack/finance/detail-order-balance-history',
        {
          params: {
            balance_history_id: parseInt(state.balanceHistoryId, 10),
            date: state.dateSelected,
            offset: parseInt(state.table.offset, 10),
            limits: parseInt(state.table.perPage, 10),
          },
        },
      )
        .then(res => {
          commit('UPDATE_ORDER_BALANCE_HISTORY', res.data.data)
        })
    },
  },
}
