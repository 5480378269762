import axiosIns from '@/libs/axios'
import moment from 'moment'
import { getField, updateField } from 'vuex-map-fields'
import {
  axiosKompack,
  hideAccount,
  today,
  formatYmd,
  last30,
  axiosKomship,
  axiosKomtim,
  axiosMockapi,
} from '../helpers'

export default {
  namespaced: true,
  state: {
    listPartnersState: [],
    table: {
      perPage: 50,
      currentPage: 1,
      totalRows: 0,
    },
    partnerSelected: '',
    dataProducts: [],
    phone: '-',
  },
  getters: {
    getField,
    listPartners(state) {
      return state.listPartnersState.map(item => (item.outbound_queue === 0
        ? {
          label: item.name,
          value: item.id,
          disabled: false,
        } : {
          label: `${item.name} (Masih ada antrian)`,
          value: item.id,
          disabled: true,
        }))
    },
    dataProductsMod(state) {
      return state.dataProducts.map(item => ({
        ...item,
        total: 0,
        product_id: item.id,
        variants: item.variant.map(i => ({
          total: 0,
          warehouse_variant_id: i.variant_id,
          option_id: i.option_id,
          variant_name: i.variant_name,
          variant_stock: i.variant_stock,
        })),
      }))
    },
  },
  mutations: {
    updateField,
    UPDATE_LIST_PARTNERS(state, listPartners) {
      state.listPartnersState = listPartners
    },
    UPDATE_DATA_PRODUCT(state, dataProducts) {
      state.dataProducts = dataProducts
    },
    UPDATE_DATA_PHONE(state, phone) {
      state.phone = phone
    },
    RESET_PRODUCTS(state) {
      state.dataProducts = []
    },
  },
  actions: {
    async init({ dispatch }) {
      return new Promise((resolve, reject) => {
        dispatch('getListPartners')
          .then(res => resolve(res))
          .catch(err => reject(err))
      })
    },
    async getListPartners({ commit, state }) {
      return new Promise((resolve, reject) => {
        axiosKompack()
          .get('v1/kompack/outbound/non-pickup/partners')
          .then(response => {
            commit('UPDATE_LIST_PARTNERS', response.data.data)
            resolve(response)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    async getListProductByPartner({ commit, state }) {
      return new Promise((resolve, reject) => {
        axiosKompack()
          .get(
            `v1/kompack/outbound/non-pickup/product/${state.partnerSelected}`,
          )
          .then(response => {
            commit('UPDATE_DATA_PRODUCT', response.data.data)
            resolve(response)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    async submission({ commit, state }, data) {
      const payload = {
        partner_id: state.partnerSelected,
        products: data.products,
        memo: data.memo,
      }
      return new Promise((resolve, reject) => {
        axiosKompack()
          .post('v1/kompack/outbound/non-pickup/store', payload)
          .then(response => {
            if (response.data.status === 'failed') {
              reject(response.data.message)
            } else {
              let noPartner = response.data.data.pic_phone
              if (noPartner[0] === '0') {
                noPartner = `+62${noPartner.substring(1)}`
                commit('UPDATE_DATA_PHONE', noPartner)
              } else {
                commit('UPDATE_DATA_PHONE', response.data.data.pic_phone)
              }
              resolve(response.data.data.pic_phone)
            }
          })
          .catch(err => {
            reject(err)
          })
      })
    },
  },
}
