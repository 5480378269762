import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'
// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import auth from './auth'
import menuaccess from './menuaccess'
import saldo from './saldo'
import withdrawDetail from './saldo/withdraw_detail'
import saldoDetail from './saldo/saldo_detail'
import outboundCosts from './saldo/outbound_costs'
import outboundNonPacking from './outbound/non_packing'
import brokenItemSubmission from './outbound/broken_item_submission'
import dashboard from './dashboard'
import deliverConstraints from './deliver-constraints/deliver-constraints'

Vue.use(Vuex)
const ls = new SecureLS({ isCompression: false })

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    menuaccess,
    saldo,
    withdrawDetail,
    saldoDetail,
    outboundCosts,
    outboundNonPacking,
    brokenItemSubmission,
    dashboard,
    deliverConstraints,
  },
  plugins: [createPersistedState({
    key: 'vuex-store',
    storage: {
      getItem: key => {
        if (process.env.NODE_ENV === 'production') {
          return ls.get(key)
        }
        return localStorage.getItem(key)
      },
      setItem: (key, value) => {
        if (process.env.NODE_ENV === 'production') {
          return ls.set(key, value)
        }
        return localStorage.setItem(key, value)
      },
      removeItem: key => {
        if (process.env.NODE_ENV === 'production') {
          return ls.remove(key)
        }
        return localStorage.removeItem(key)
      },
    },
  })],
  strict: process.env.DEV,
})
