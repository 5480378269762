import axiosIns from '@/libs/axios'
import moment from 'moment'
import { getField, updateField } from 'vuex-map-fields'
import {
  axiosKompack,
  hideAccount,
  today,
  formatYmd,
  last30,
  axiosKomship,
  axiosKomtim,
  axiosMockapi,
  kompackDate,
} from '../helpers'

export default {
  namespaced: true,
  state: {
    listPartnersState: [],
    listOutboundNonPickup: [],
    listWaitingResponse: [],
    detailOutbound: {},
    table: {
      limit: 50,
      offsetOutbound: 0,
      offsetWaiting: 0,
      totalRows: 0,
    },
    dateRange: {
      startDate: kompackDate,
      endDate: today,
    },
    partnerSelected: '',
    statusSelected: '',
    idDetail: '',
    nextFetchListOutbound: false,
    nextFetchListWaiting: false,
  },
  getters: {
    getField,
    listPartners(state) {
      return state.listPartnersState.map(item => ({
        text: item.name,
        value: item.id,
        unsubscribe: item.unsubscribe,
      }))
    },
  },
  mutations: {
    updateField,
    UPDATE_LIST_PARTNERS(state, listPartners) {
      state.listPartnersState = [{ name: 'Semua', id: '' }, ...listPartners]
    },
    UPDATE_LIST_OUTBOUND(state, listOutbound) {
      state.listOutboundNonPickup = listOutbound
    },
    UPDATE_NEXT_LIST_OUTBOUND(state, listOutbound) {
      state.listOutboundNonPickup.push(...listOutbound)
    },
    UPDATE_LIST_WAITING(state, listOutbound) {
      state.listWaitingResponse = listOutbound
    },
    UPDATE_NEXT_LIST_WAITING(state, listOutbound) {
      state.listWaitingResponse.push(...listOutbound)
    },
    UPDATE_DETAIL_OUTBOUND(state, detailOutbound) {
      state.detailOutbound = detailOutbound
    },
    UPDATE_ID_DETAIL(state, id) {
      state.idDetail = id
    },
    UPDATE_OFFSET_OUTBOUND(state, offset) {
      state.table.offsetOutbound += offset
    },
    UPDATE_OFFSET_WAITING(state, offset) {
      state.table.offsetWaiting += offset
    },
    IS_NEXT_LIST_OUTBOUND(state, isNext) {
      state.nextFetchListOutbound = isNext
    },
    IS_NEXT_LIST_WAITING(state, isNext) {
      state.nextFetchListWaiting = isNext
    },
  },
  actions: {
    init({ dispatch }) {
      return new Promise((resolve, reject) => {
        dispatch('getListOutboundNonPickup')
          .then(res => resolve(res))
          .catch(err => reject(err))
        dispatch('getListWaitingResponse')
          .then(res => resolve(res))
          .catch(err => reject(err))
        dispatch('getListPartners')
          .then(res => resolve(res))
          .catch(err => reject(err))
      })
    },

    // LIST PARTNER
    getListPartners({ commit, state }) {
      return new Promise(reject => {
        axiosKompack()
          .get('v1/kompack/outbound/non-pickup/history-partners')
          .then(response => {
            commit('UPDATE_LIST_PARTNERS', response.data.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },

    // HISTORY OUTBOUND TAB
    getListOutboundNonPickup({ commit, state }) {
      state.table.offsetOutbound = 0

      return new Promise((resolve, reject) => {
        axiosKompack()
          .get('v1/kompack/outbound/non-pickup', {
            params: {
              partner_id: state.partnerSelected,
              status: state.statusSelected,
              start_date: state.dateRange.startDate ? formatYmd(state.dateRange.startDate) : state.dateRange.startDate,
              end_date: state.dateRange.endDate ? formatYmd(state.dateRange.endDate) : state.dateRange.endDate,
              offset: 0,
              limit: state.table.limit,
            },
          })
          .then(response => {
            commit('UPDATE_LIST_OUTBOUND', response.data.data)
            commit('UPDATE_OFFSET_OUTBOUND', response.data.data.length)
            resolve(response.data)

            if (response.data.data.length === state.table.limit) {
              commit('IS_NEXT_LIST_OUTBOUND', true)
            }
          })
          .catch(err => {
            reject(err)
          })
      })
    },

    // NEXT FETCH HISTORY OUTBOUND TAB
    nextListOutboundNonPickup({ commit, state }) {
      return new Promise((resolve, reject) => {
        axiosKompack()
          .get('v1/kompack/outbound/non-pickup', {
            params: {
              partner_id: state.partnerSelected,
              status: state.statusSelected,
              start_date: state.dateRange.startDate ? formatYmd(state.dateRange.startDate) : state.dateRange.startDate,
              end_date: state.dateRange.endDate ? formatYmd(state.dateRange.endDate) : state.dateRange.endDate,
              offset: state.table.offsetOutbound,
              limit: state.table.limit,
            },
          })
          .then(response => {
            commit('UPDATE_NEXT_LIST_OUTBOUND', response.data.data)
            commit('UPDATE_OFFSET_OUTBOUND', response.data.data.length)
            resolve(response.data)

            if (response.data.data.length === state.table.limit) {
              commit('IS_NEXT_LIST_OUTBOUND', true)
            } else {
              commit('IS_NEXT_LIST_OUTBOUND', false)
            }
          })
          .catch(err => {
            reject(err)
          })
      })
    },

    // WAITING RESPONSE TAB
    getListWaitingResponse({ commit, state }) {
      state.table.offsetWaiting = 0

      return new Promise((resolve, reject) => {
        axiosKompack()
          .get('v1/kompack/outbound/non-pickup/waiting-response', {
            params: {
              partner_id: state.partnerSelected,
              status: state.statusSelected,
              start_date: state.dateRange.startDate ? formatYmd(state.dateRange.startDate) : state.dateRange.startDate,
              end_date: state.dateRange.endDate ? formatYmd(state.dateRange.endDate) : state.dateRange.endDate,
              offset: 0,
              limit: state.table.limit,
            },
          })
          .then(response => {
            commit('UPDATE_LIST_WAITING', response.data.data)
            commit('UPDATE_OFFSET_WAITING', response.data.data.length)
            resolve(response.data)

            if (response.data.data.length === state.table.limit) {
              commit('IS_NEXT_LIST_WAITING', true)
            }
          })
          .catch(err => {
            reject(err)
          })
      })
    },

    // NEXT FETCH WAITING RESPONSE TAB
    nextListWaitingResponse({ commit, state }) {
      return new Promise((resolve, reject) => {
        axiosKompack()
          .get('v1/kompack/outbound/non-pickup/waiting-response', {
            params: {
              partner_id: state.partnerSelected,
              status: state.statusSelected,
              start_date: state.dateRange.startDate ? formatYmd(state.dateRange.startDate) : state.dateRange.startDate,
              end_date: state.dateRange.endDate ? formatYmd(state.dateRange.endDate) : state.dateRange.endDate,
              offset: state.table.offsetWaiting,
              limit: state.table.limit,
            },
          })
          .then(response => {
            commit('UPDATE_NEXT_LIST_WAITING', response.data.data)
            commit('UPDATE_OFFSET_WAITING', response.data.data.length)
            resolve(response.data)

            if (response.data.data.length === state.table.limit) {
              commit('IS_NEXT_LIST_WAITING', true)
            } else {
              commit('IS_NEXT_LIST_WAITING', false)
            }
          })
          .catch(err => {
            reject(err)
          })
      })
    },

    // DETAIL OUTBOUND
    getDetailOutbound({ commit, state }) {
      return new Promise((resolve, reject) => {
        axiosKompack()
          .get(`v1/kompack/outbound/non-pickup/${state.idDetail}/detail`)
          .then(async response => {
            await commit('UPDATE_DETAIL_OUTBOUND', response.data.data)
            resolve(response.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },

    // APPROVAL
    async approve({ commit, state }) {
      return new Promise((resolve, reject) => {
        axiosKompack()
          .put(`v1/kompack/outbound/non-pickup/${state.idDetail}/approve`)
          .then(async response => {
            resolve(response.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },

    // REJECTION
    async refuse({ commit, state }) {
      return new Promise((resolve, reject) => {
        axiosKompack()
          .put(`v1/kompack/outbound/non-pickup/${state.idDetail}/reject`)
          .then(async response => {
            resolve(response.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
  },
}
